body {
    box-sizing: border-box;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.card {
    margin: 0 2% !important;
}

.cardheight {
    max-width: 345px;
}

.cardmedia {
    height: 80px;
}